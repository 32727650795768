<template>
  <section>
    <div v-if="questionIndex == -1">
      <p class="quiz-answers-wrong">Respostas incorretas: <b>{{ wrongAnswers.length }}</b></p>
      <p class="quiz-answers-right">Respostas corretas: <b>{{ rightAnswers.length }}</b></p>
    </div>
    <div class="quiz" v-if="questionIndex >= 0">
      <div class="flex-row">
        <h3 class="quiz-question__title">{{ questionIndex + 1 }}.</h3>
        <h4 class="quiz-question__text">{{ question.text }}</h4>
      </div>
      <ul class="quiz-option-list">
        <li v-for="option in question.answers" @click="select(option.id)" :class="{
          'quiz-option-item': true,
          'quiz-option-item--selected': selected === option?.id,
          'quiz-option-item--wrong': result == 'wrong' && selected == option?.id,
          'quiz-option-item--right': seeAnswer && option?.is_correct == 1,
        }">
          <b-form-radio name="radio" size="lg" class="quiz-option-item__check" v-model="selected" :value="option.id"
            :checked="option.id === selected" :disabled="!!result"></b-form-radio>
          <span class="quiz-option-item__title">
            {{ option.text }}
          </span>
        </li>
      </ul>
      <div class="quiz-option-empty" v-if="chooseOne">
        <IconSax name="danger" />
        <span>
          Escolha uma das alternativas
        </span>
      </div>
      <div class="quiz-controls">
        <div :class="{
          'quiz-result': true,
          'quiz-result--right': result === 'valid',
          'quiz-result--wrong': result === 'wrong'
        }">
          <span class="quiz-result__title">{{ feedbacks[result]?.title }}</span>
          <div class="quiz-result__content">
            <span class="quiz-result__text">{{ feedbacks[result]?.text }}</span>
            <a v-if="result === 'wrong'" class="quiz-result__answer" @click="seeAnswer = !seeAnswer">
              {{ seeAnswer ? "Ocultar respostar" : "Ver resposta correta" }}
            </a>
          </div>
        </div>
        <ButtonPlay :disabled="questionIndex == 0" @click="prev()">
          Anterior
        </ButtonPlay>
        <ButtonPlay v-if="!result" @click="check()">
          Verificar resposta
        </ButtonPlay>
        <ButtonPlay v-if="result" @click="remake()">
          Refazer
        </ButtonPlay>
        <ButtonPlay v-if="!isLastQuestion" @click="next()">
          Próximo
        </ButtonPlay>
        <ButtonPlay v-if="isLastQuestion" :disabled="!selected || !canComplete" @click="$emit('complete')">
          Concluir
        </ButtonPlay>
      </div>
      <div>

      </div>
    </div>
  </section>
</template>
<script>

import fp from "@/utils/fp"
import { mapActions } from "vuex"

export default {
  name: "LessonQuiz",
  props: ["lesson", "canComplete"],
  data() {
    return {
      questionIndex: 0,
      selected: null,
      seeAnswer: false,
      answers: [],
      chooseOne: false,
      wrongAnswers: [],
      rightAnswers: [],
      feedbacks: {
        valid: {
          title: "Resposta correta",
          text: "Muito bem! Parabéns pelo acerto"
        },
        wrong: {
          title: "Resposta incorreta",
          text: "Não foi dessa vez!"
        }
      }
    }
  },
  computed: {
    question() {
      return this.lesson.questions[this.questionIndex] || {};
    },
    isLastQuestion() {
      return this.questionIndex == this.lesson.questions.length - 1;
    },
    answer: {
      get() {
        return this.answers[this.questionIndex]
      },
      set(answer) {
        this.answers = fp.set(this.questionIndex, answer, this.answers)
      }
    },
    validAnswer() {
      return fp.find(it => it.is_correct, this.question.answers)
    },
    result() {
      if (this.answer) {
        return this.answer == this.validAnswer.id ? "valid" : "wrong";
      } else {
        return null
      }
    },

  },
  methods: {
    ...mapActions({
      'actionAnswerLessonQuiz': 'courses/actionAnswerLessonQuiz'
    }),
    select(option) {
      if (this.result) {
        return
      }
      this.selected = option;
      this.chooseOne = false;
    },
    check() {
      this.actionAnswerLessonQuiz({
        answer_id: this.selected,
        question_id: this.question.id
      })
      this.chooseOne = !this.selected;
      this.answer = this.selected;
      if (this.isLastQuestion) {
        this.$emit("completed", this.lesson);
      }
      this.$nextTick();
    },
    remake() {
      this.seeAnswer = false;
      this.answer = null;
      this.selected = null;
      this.chooseOne = false;
    },
    prev() {
      this.questionIndex--;
      this.selected = this.answer;
      this.chooseOne = false;
      this.seeAnswer = false;
    },
    next() {
      this.actionAnswerLessonQuiz({
        answer_id: this.selected,
        question_id: this.question.id
      })
      this.questionIndex++;
      this.selected = this.answer;
      this.chooseOne = false;
      this.seeAnswer = false;
    }
  },
  mounted() {
    fp.map((question, idx) => {
      if (!question.my_answer) {
        return
      }
      this.answers[idx] = question.my_answer.id
      if (question.my_answer.is_correct) {
        this.rightAnswers.push(idx)
      } else {
        this.wrongAnswers.push(idx)
      }
    }, this.lesson.questions)

    this.questionIndex = fp.findIndex(it => !it.my_answer, this.lesson.questions)
  }
}

</script>

<style lang="scss" scoped>
.quiz {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  color: var(--fontcolor);
}

.quiz-question__title {
  all: unset;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 1.35rem */
}

.quiz-answers-wrong {
  color: var(--dangercolor);
}

.quiz-answers-right {
  color: var(--successcolor);
}

.quiz-question__text {
  all: unset;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.quiz-option-list {
  all: unset;
  display: flex;
  flex-direction: column;
}

.quiz-option-item {
  all: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;

  &--right {
    background-color: var(--successcolor);
  }

  &--wrong {
    background-color: var(--dangercolor);
  }
}

.quiz-controls {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.quiz-result {
  flex: 1;
  display: flex;
  flex-direction: column;

  &--right {
    color: var(--successcolor);
  }

  &--wrong {
    color: var(--dangercolor);
  }
}

::v-deep .quiz-option-empty {
  display: flex;
  padding: 1rem;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 0.25rem;
  border: 1px solid var(--feedback-warning-300, #FEC84B);
  background: var(--feedback-warning-100, #fff0d4);

  svg {
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
  }

  svg path {
    fill: var(--feedback-warning-600, #DC6803);
  }
}

.quiz-result__content {
  display: flex;
  gap: 1rem;
}

.quiz-result__title {
  font-weight: 900;
}
</style>
