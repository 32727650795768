var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(_vm.questionIndex == -1)?_c('div',[_c('p',{staticClass:"quiz-answers-wrong"},[_vm._v("Respostas incorretas: "),_c('b',[_vm._v(_vm._s(_vm.wrongAnswers.length))])]),_c('p',{staticClass:"quiz-answers-right"},[_vm._v("Respostas corretas: "),_c('b',[_vm._v(_vm._s(_vm.rightAnswers.length))])])]):_vm._e(),(_vm.questionIndex >= 0)?_c('div',{staticClass:"quiz"},[_c('div',{staticClass:"flex-row"},[_c('h3',{staticClass:"quiz-question__title"},[_vm._v(_vm._s(_vm.questionIndex + 1)+".")]),_c('h4',{staticClass:"quiz-question__text"},[_vm._v(_vm._s(_vm.question.text))])]),_c('ul',{staticClass:"quiz-option-list"},_vm._l((_vm.question.answers),function(option){return _c('li',{class:{
        'quiz-option-item': true,
        'quiz-option-item--selected': _vm.selected === option?.id,
        'quiz-option-item--wrong': _vm.result == 'wrong' && _vm.selected == option?.id,
        'quiz-option-item--right': _vm.seeAnswer && option?.is_correct == 1,
      },on:{"click":function($event){return _vm.select(option.id)}}},[_c('b-form-radio',{staticClass:"quiz-option-item__check",attrs:{"name":"radio","size":"lg","value":option.id,"checked":option.id === _vm.selected,"disabled":!!_vm.result},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('span',{staticClass:"quiz-option-item__title"},[_vm._v(" "+_vm._s(option.text)+" ")])],1)}),0),(_vm.chooseOne)?_c('div',{staticClass:"quiz-option-empty"},[_c('IconSax',{attrs:{"name":"danger"}}),_c('span',[_vm._v(" Escolha uma das alternativas ")])],1):_vm._e(),_c('div',{staticClass:"quiz-controls"},[_c('div',{class:{
        'quiz-result': true,
        'quiz-result--right': _vm.result === 'valid',
        'quiz-result--wrong': _vm.result === 'wrong'
      }},[_c('span',{staticClass:"quiz-result__title"},[_vm._v(_vm._s(_vm.feedbacks[_vm.result]?.title))]),_c('div',{staticClass:"quiz-result__content"},[_c('span',{staticClass:"quiz-result__text"},[_vm._v(_vm._s(_vm.feedbacks[_vm.result]?.text))]),(_vm.result === 'wrong')?_c('a',{staticClass:"quiz-result__answer",on:{"click":function($event){_vm.seeAnswer = !_vm.seeAnswer}}},[_vm._v(" "+_vm._s(_vm.seeAnswer ? "Ocultar respostar" : "Ver resposta correta")+" ")]):_vm._e()])]),_c('ButtonPlay',{attrs:{"disabled":_vm.questionIndex == 0},on:{"click":function($event){return _vm.prev()}}},[_vm._v(" Anterior ")]),(!_vm.result)?_c('ButtonPlay',{on:{"click":function($event){return _vm.check()}}},[_vm._v(" Verificar resposta ")]):_vm._e(),(_vm.result)?_c('ButtonPlay',{on:{"click":function($event){return _vm.remake()}}},[_vm._v(" Refazer ")]):_vm._e(),(!_vm.isLastQuestion)?_c('ButtonPlay',{on:{"click":function($event){return _vm.next()}}},[_vm._v(" Próximo ")]):_vm._e(),(_vm.isLastQuestion)?_c('ButtonPlay',{attrs:{"disabled":!_vm.selected || !_vm.canComplete},on:{"click":function($event){return _vm.$emit('complete')}}},[_vm._v(" Concluir ")]):_vm._e()],1),_c('div')]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }